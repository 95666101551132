import React, { useState, useRef } from 'react';
import { db, storage} from "../Firebase"
import { collection, addDoc } from "firebase/firestore";
import { ref, uploadBytesResumable, getDownloadURL } from "firebase/storage";
import { CircularIndeterminate } from '../loadanimation';
import Navbar from './Navbar';
import axios from 'axios';
import Footer from './Footer';



const Form = () => {
  const [file, setFile] = useState(null);
  const [designation, setDesignation] = useState('');
  const [paymentFees, setPaymentFees] = useState('');
  const [paymentMethod, setPaymentMethod] = useState('offline');
  const [loading, setLoading] = useState(false)
  
  const [formData, setFormData] = useState({
    title: '',
    firstName: '',
    middleName: '',
    lastName: '',
    gender: '',
    dob: '',
    email: '',
    mobileNumber: '',
    whatsappNumber: '',
    address: '',
    country: '',
    residenceType: '',
    designation: '',
    inService:'',
    organization: '',
    accomodationNeeded: '',
    abstractTitle: '',
    theme: '',
    downloadLink: '',
    modeOfTravel: '',
    DOA: '',
    DOD: '',
    fees : '',
    PaymentMode: 'Offline',
    UTR: '',
    PaymentStatus: 'unapproved'
    
  });
  const form = useRef();

  const handlePaymentChange = (event) => {
    setPaymentMethod(event.target.value);
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    if (name === 'designation') {
      setDesignation(value);
      switch (value) {
        case 'Delegates':
          setPaymentFees('Rs. 4500/-');
          break;
        case 'Spouse':
          setPaymentFees('Rs. 2500/-');
          break;
        case 'Research-Scholars-with-Fellowship':
          setPaymentFees('Rs. 2500/-');
          break;
        case 'Research-Scholar-without-Fellowship':
          setPaymentFees('Rs. 1500/-');
          break;
        case 'Student':
          setPaymentFees('Rs. 1000/-');
          break;
        default:
          setPaymentFees('');
      }}

    setFormData({
      ...formData,
      [name]: value
    });
  };

  console.log(formData)
  console.log(designation)

  const handleFileChange = (e) => {
    const selectedFile = e.target.files[0];
    if (selectedFile && selectedFile.type === 'application/pdf') {
        setFile(selectedFile);
        
    } else {
        setFile(null);
        
    }
};


  const handleSubmit = async (e) => {
      e.preventDefault();
        setLoading(true)

        if (formData.UTR) {
            formData.PaymentMode = 'Online';
            
          } else {
            formData.PaymentMode = 'Offline';
            
          }

        try {

            const storageRef = ref(storage, `pdfs/${file.name}`);;
            const uploadTask = await uploadBytesResumable(storageRef, file);
            const downloadLink = await getDownloadURL(uploadTask.ref);
            console.log(downloadLink)
      
        

            const docRef = await addDoc(collection(db, "user-info"), {


        Title: formData.title,
        Firstname: formData.firstName,
        MiddleName: formData.middleName,
        Lastname: formData.lastName,
        Gender: formData.gender,
        DOB: formData.dob,
        Email: formData.email,
        Phone: formData.mobileNumber,
        WhastsaapNaumber: formData.whatsappNumber,
        Address: formData.address,
        Country: formData.country,
        ResidenceType: formData.residenceType,
        Designation: formData.designation,
        InService: formData.inService,
        Organization: formData.organization,
        AccomodationNeeded: formData.accomodationNeeded,
        AbstractTitle: formData.abstractTitle,
        Theme:formData.theme,
        DownloadLink:downloadLink,
        ModeOfTravel:formData.modeOfTravel,
        DateOfArival:formData.DOA,
        DateOfDeparture:formData.DOD,
        PaymentFees:paymentFees,
        UTRNumber: formData.UTR,
        PaymentMode: formData.PaymentMode,
        PaymentStatus:formData.PaymentStatus,
                
            });
            console.log(docRef)
            if(docRef){
              sendEmail()
            }
            setLoading(false)
      
    }catch (error) {
        console.error("Error adding document: ", error);
    }
  };

  const sendEmail = async () => {
    
    await axios.post('https://icms2024-honi2302s-projects.vercel.app/send-email', formData)
    .then(response => {
        alert('Email sent successfully!');
        window.location.href = 'https://icms2024.in/';
        console.log(response)
    })
    .catch(error => {
        alert('Failed to send email.');
        console.log(error)
    });
  };

  return (
    <>
<header className='absolute inset-x-0 top-0 z-50'>
<Navbar/>
</header>



<div className="relative  h-screen ">
      <div
        className="absolute inset-0 bg-cover  bg-center"
        style={{ backgroundImage: 'url(https://icms2024.in/images/h1.jpg)' }}
      ></div>
      <div className="absolute inset-0 bg-black opacity-70"></div>
      <div className="relative h-screen overflow-y-scroll bg-gray-900 bg-opacity-30">
        <div className="flex justify-center items-center min-h-screen">
          <div className="bg-white mt-20 mb-10 sm:bg-opacity-70 bg-opacity-50 z-40 p-8 rounded shadow-lg max-w-md w-full">

    <form ref={form} className="max-w-md mx-auto  opacity-100" onSubmit={handleSubmit}>
      <h2 className="text-lg my-10 font-bold mb-4">Registration for Conference/Workshop</h2>
 
      <div className='mt-10 mb-4 w-[90] mx-5 font-bold text-xl'>
   Personal Information
</div>
    
      <div className="grid w-[90] mx-5 grid-cols-1 my-10 gap-x-8 gap-y-6 sm:grid-cols-2">
        <select name="title" className="z-40 block w-full rounded-md border-0 px-3.5 py-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6" onChange={handleChange}>
          <option value="" disabled selected>Select Title</option>
          <option value="Dr">Dr.</option>
          <option value="Mr">Mr.</option>
          <option value="Ms">Ms.</option>
          <option value="Mrs">Mrs.</option>
        </select>
      </div>
 
      <div className="relative z-0 w-[90] mx-5 mb-5 group">
      <label htmlFor="firstName" className="block text-sm font-semibold leading-6 text-gray-900">First Name</label>
        <input type="text" name="firstName" className="block w-full rounded-md border-0 px-3.5 py-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6" placeholder=" " onChange={handleChange} required />
      </div>

      <div className="relative w-[90] mx-5 z-0  mb-5 group">
      <label htmlFor="middleName" className="block text-sm font-semibold leading-6 text-gray-900">Middle Name</label>
        <input type="text" name="middleName"  className="block w-full rounded-md border-0 px-3.5 py-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6" placeholder=" " onChange={handleChange} />
        
      </div>

      <div className="relative w-[90] mx-5 z-0  mb-5 group">
      <label htmlFor="lastName" className="block text-sm font-semibold leading-6 text-gray-900">Last Name</label>
        <input type="text" name="lastName" className="block w-full rounded-md border-0 px-3.5 py-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6" placeholder=" " onChange={handleChange} required />
       
      </div>

      <div className="relative w-[90] mx-5 z-0  mb-5 group">
        <div className="flex items-center">
        <input type="radio" name="gender" value="Male" onChange={handleChange} />
        <label htmlFor="male" className="ml-2 mr-4">Male</label>
        
        <input type="radio" name="gender" value="Female" onChange={handleChange} />
        <label htmlFor="female" className="ml-2 mr-4">Female</label>
          
          <input type="radio" name="gender" value="Prefer Not to say" onChange={handleChange} />
          <label htmlFor="transgender" className="ml-2 mx-2">Prefer not to say</label>
          
         
        </div>
      </div>

      <div className="relative w-[90] mx-5 z-0  mb-5 group">
      <label htmlFor="dob" className="block text-sm font-semibold leading-6 text-gray-900">Date of Birth</label>
        <input type="date" name="dob" className="block w-full rounded-md border-0 px-3.5 py-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6" onChange={handleChange} required />
       
      </div>

      <div className="relative w-[90] mx-5 z-0  mb-5 group">
        <label htmlFor="email" className="block text-sm font-semibold leading-6 text-gray-900">Email</label>
        <input type="email" name="email" className="block w-full rounded-md border-0 px-3.5 py-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6" placeholder=" " onChange={handleChange} required />
      </div>

      <div className="relative w-[90] mx-5 z-0  mb-5 group">
      <label htmlFor="mobileNumber" className="block text-sm font-semibold leading-6 text-gray-900">Mobile Number</label>
        <input type="tel" name="mobileNumber" className="block w-full rounded-md border-0 px-3.5 py-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6" placeholder=" " onChange={handleChange} required />
       
      </div>

      <div className="relative w-[90] mx-5 z-0  mb-5 group">
      <label htmlFor="whatsappNumber" className="block text-sm font-semibold leading-6 text-gray-900">WhatsApp Mobile Number</label>
        <input type="tel" name="whatsappNumber" className="block w-full rounded-md border-0 px-3.5 py-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6" placeholder=" " onChange={handleChange} />
       
      </div>

      <div className="relative w-[90] mx-5 z-0  mb-5 group">
      <label htmlFor="address" className="block text-sm font-semibold leading-6 text-gray-900">Address for Correspondence</label>
        <textarea name="address" className="block w-full rounded-md border-0 px-3.5 py-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6" placeholder=" " onChange={handleChange} required></textarea>  
      </div>

      <div className="relative w-[90] mx-5 z-0  mb-5 group">
        <select name="country" className="block w-full rounded-md border-0 px-3.5 py-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6" onChange={handleChange}>
          <option value="" disabled selected>Select Country</option>
          {/* Add more country options as needed */}
          <option value="India">India</option>
          <option value="India">Other</option>
        </select>
      </div>

      <div className="relative w-[90] mx-5 z-0  mb-5 group">
        <select name="residenceType" className="block w-full rounded-md border-0 px-3.5 py-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6" onChange={handleChange}>
          <option value="" disabled selected>Select Residence Type</option>
          {/* Add more residence type options as needed */}
          <option value="Urban">Urban</option>
          <option value="Rural">Rural</option>
        </select>
      </div>
      
      


<div className='mt-10 mb-4 w-[90] mx-5 font-bold text-xl'>
    Professional Details
</div>

<div className="relative w-[90] mx-5 z-0  mb-5 group">
        <select name="designation" className="block w-full rounded-md border-0 px-3.5 py-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6" onChange={handleChange}>
          <option value="" disabled selected>Select Designation</option>
          {/* Add more category options as needed */}
          <option value="Delegates">Delegates</option>
          <option value="Spouse">Spouse</option>
          <option value="Research-Scholars-with-Fellowship">Research Scholars with Fellowship</option>
          <option value="Research-Scholar-without-Fellowship">Research Scholar without Fellowship</option>
          <option value="Student">Student</option>
        </select>
      </div>

      <div className="relative w-[90] mx-5 z-0  mb-5 group">
      <label htmlFor="In Service/Superannuated" className="block text-sm font-semibold leading-6 text-gray-900">In Service/Superannuated</label>
        <input type="text" name="inService" className="block w-full rounded-md border-0 px-3.5 py-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6" placeholder=" " onChange={handleChange} required />
      
      </div>

      <div className="relative w-[90] mx-5 z-0  mb-5 group">
      <label htmlFor="organization" className="block text-sm font-semibold leading-6 text-gray-900">University/Institute/Company/Organization Name</label>
        <input type="text" name="organization" className="block w-full rounded-md border-0 px-3.5 py-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6" placeholder=" " onChange={handleChange} required />
        
      </div>

      <div className="relative w-[90] mx-5 z-0  mb-5 group">
        <select name="accomodationNeeded" className="block w-full rounded-md border-0 px-3.5 py-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6" onChange={handleChange}>
          <option value="" disabled selected>Accomdation Assistance Needed</option>
          {/* Add more country options as needed */}
          <option value="Yes">Yes</option>
          <option value="No">No</option>
          
        </select>
      </div>

<div className='mt-10 mb-4 w-[90] mx-5 font-bold text-xl'>
Abstract Details
</div>
      
<div className="relative w-[90] mx-5 z-0  mb-5 group">
      <label htmlFor="Abstract-field" className="block text-sm font-semibold leading-6 text-gray-900">Tentative Title of Abstract/Field of Specialization</label>
        <input type="text" name="abstractTitle" className="block w-full rounded-md border-0 px-3.5 py-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6" placeholder=" " onChange={handleChange} required />
      
      </div>

<div className="relative w-[90] mx-5 z-0  mb-5 group">
        <select name="theme" className="block w-full rounded-md border-0 px-3.5 py-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6" onChange={handleChange}>
          <option value="" disabled selected>Theme under which abstract is to be submitted</option>
          {/* Add more country options as needed */}
          <option value="Microfossils and their evolutionary dynamics">Microfossils and their evolutionary dynamics</option>
          <option value="Advancements in Phanerozoic Biostratigraphy and Biochronology
          employing microfossils. Diachronism in Microfossil events.">Advancements in Phanerozoic Biostratigraphy and Biochronology
          employing microfossils. Diachronism in Microfossil events.</option>
          <option value="Industrial Applications of Micropalaeontology including
          Hydrocarbon exploration">Industrial Applications of Micropalaeontology including
          Hydrocarbon exploration</option>
          <option value="Application of Microfossils in Indian Stratigraphy and solving
          stratigraphic boundary problems from Precambrian to Holocene">Application of Microfossils in Indian Stratigraphy and solving
          stratigraphic boundary problems from Precambrian to Holocene</option>
          <option value="Taxonomic advancement in different microfossil groups">Taxonomic advancement in different microfossil groups </option>
          <option value="Use of geochemical analyses of microfossils in Paleoceanography,
          paleoclimate and paleoenvironment">Use of geochemical analyses of microfossils in Paleoceanography,
          paleoclimate and paleoenvironment</option>
          <option value="Application of Microfossils in Paleoclimate, Palaeoceanography,
paleoenvironmental reconstructions and climate change and
sustainability">Application of Microfossils in Paleoclimate, Palaeoceanography,
paleoenvironmental reconstructions and climate change and
sustainability </option>
          <option value="Micropalaeontology in Archaeology & Geochronology">Micropalaeontology in Archaeology & Geochronology</option>
          <option value="Studies on ichnology, bryozoan, sponge spicules, testate amoebae,
          coral sclerites, etc.">Studies on ichnology, bryozoan, sponge spicules, testate amoebae,
          coral sclerites, etc.</option>
          <option value="Micro vertebrate fossils, their evolution and significance in
          stratigraphy">Micro vertebrate fossils, their evolution and significance in
          stratigraphy</option>
          <option value="Culture studies & Experimental, Molecular and Evolutionary
          micropalaeontology">Culture studies & Experimental, Molecular and Evolutionary
          micropalaeontology</option>
          <option value="Application of Microfossils in understanding coastal dynamics and
          sea level changes.">Application of Microfossils in understanding coastal dynamics and
          sea level changes.</option>
          <option value="Taphonomy & Diagenetic effects on preservation">Taphonomy & Diagenetic effects on preservation</option>
          <option value="Application of statistical analysis of micropaleontological data for
          paleoclimatic interpretations">Application of statistical analysis of micropaleontological data for
          paleoclimatic interpretations</option>
          <option value="Any other topic relevant to Micropaleontology and stratigraphy">Any other topic relevant to Micropaleontology and stratigraphy</option>

          
        </select>
</div>
      

      <div className="relative w-[90] mx-5 z-0  mb-5 group">
        <label htmlFor="password" className="block text-sm font-semibold leading-6 text-gray-900">Upload Abstract </label>
        <input type="file" required className='block w-full rounded-md border-0 px-3.5 py-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6' accept="application/pdf" onChange={handleFileChange} />
        <p className='text-left text-red-900 font-xs'>
           Upload only pdf
        </p>
      </div>
      

<div className='mt-10 mb-4 w-[90] mx-5 font-bold text-xl'>
    Travelling Details
</div>


<div className="relative w-[90] mx-5 z-0  mb-5 group">
        <select name="modeOfTravel" className="block w-full rounded-md border-0 px-3.5 py-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6" onChange={handleChange}>
          <option value="" disabled selected>Mode of Travel</option>
          {/* Add more country options as needed */}
          <option value="Air">Air</option>
          <option value="Train">Train</option>
          <option value="Road">Road</option>
        </select>
</div>

<div className="relative w-[90] mx-5 z-0  mb-5 group">
      <label htmlFor="Date-of-arrival" className="block text-sm font-semibold leading-6 text-gray-900">Tentative Date of Arrival</label>
        <input type="date" name="DOA" className="block w-full rounded-md border-0 px-3.5 py-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6" onChange={handleChange} required />
       
      </div>

      <div className="relative w-[90] mx-5 z-0  mb-5 group">
      <label htmlFor="Date-of-Departure" className="block text-sm font-semibold leading-6 text-gray-900">Tentative Date of Departure</label>
        <input type="date" name="DOD" className="block w-full rounded-md border-0 px-3.5 py-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6" onChange={handleChange} required />
       
      </div>

<div className='mt-10 mb-4 w-[90] mx-5 font-bold text-xl'>
    Please select your Payment options
</div>

    <div>
        <div className="flex"></div>


      <div className="flex w-[90] mx-5 items-center mb-4">
        <input
          id="offline-radio"
          type="radio"
          value="offline"
          name="payment-radio"
          className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
          checked={paymentMethod === 'offline'}
          onChange={handlePaymentChange}
        />
        <label
          htmlFor="offline-radio"
          className="block text-sm mx-4 font-semibold leading-6 text-gray-900"
        >
          Offline Payment
        </label>
      </div>
      <div className="flex w-[90] mx-5 items-center">
        <input
          id="online-radio"
          type="radio"
          value="online"
          name="payment-radio"
          className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
          checked={paymentMethod === 'online'}
          onChange={handlePaymentChange}
        />
        <label
          htmlFor="online-radio"
          className="block text-sm mx-4 font-semibold leading-6 text-gray-900"
        >
          Online Payment
        </label>
      </div>

      {paymentMethod === 'offline' && (
        <>
        <div className="relative w-[90] mx-5 z-0  mb-5 group">
      <label htmlFor="Payment-Fees" className="block text-sm font-semibold leading-6 text-gray-900">Fees for Booking</label>
        <input type="text" readOnly name="fees" className="block w-full rounded-md border-0 px-3.5 py-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6" placeholder={paymentFees} value={paymentFees} onChange={handleChange} required />
        
      </div>
       
       <p className="mt-7 mb-7 text-gray-700 dark:text-gray-700">Please Pay your fees at the time of visit to the destination</p>

       </>
      )}

      {paymentMethod === 'online' && (
        <div className="mt-4 w-[90] mx-5">
          <div className="relative z-0 w-full mt-10 mb-5 group">

          <div className="relative w-[90] mx-5 z-0  mb-5 group">
      <label htmlFor="Payment-Fees" className="block text-sm font-semibold leading-6 text-gray-900">Fees for Booking</label>
        <input type="text" readOnly name="fees" className="block w-full rounded-md border-0 px-3.5 py-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6" placeholder={paymentFees} value={paymentFees} onChange={handleChange} required />
        
      </div>



          <label
                      htmlFor="phone-number"
                      className="block text-sm font-semibold leading-6 text-gray-900"
                    >
                      This is our Account Details, Make payment and share UTR Number
                    </label>
                    <div className="relative mt-2.5 my-1">
                      <textarea
                        type="text"
                        required
                        readOnly
                        placeholder="Account Number: 10851299052&#10;IFSC Code: SBIN0001067&#10;Bank Name: State Bank of India&#10;Name of A/C holder: The Registrar&#10;Name of the institution:University of Delhi "
                        name="upi-id"
                        id="upi-id"
                        className="text-center block w-full px-2 rounded-md border-0 py-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                      />
                    </div>

                    <label
                      htmlFor="upi-id"
                      className="block text-sm font-semibold leading-6 text-gray-900"
                    >
                     Please Enter your UTR number of the payment for validaton
                    </label>
                    <div className="mt-2.5">
                    <input type="text" name="UTR" className="block w-full rounded-md border-0 px-3.5 py-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6" placeholder="Your payment will be verified in 24hr" onChange={handleChange} required />
                    </div>
          </div>
        </div>
      )}
    </div> 

    {loading? <div className='flex itemCenter'><CircularIndeterminate/></div>: <button
          type="submit"
          className="block w-[90] mx-5 m-2 rounded-md bg-indigo-600 px-3.5 py-2.5 text-center text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
        >
        Submit
        </button>}
    </form>
    </div>
    </div>
        </div>
      </div>
    

    <Footer/>
    </>
  );
};

export default Form;
 