import { initializeApp } from "firebase/app";
import { getFirestore } from "firebase/firestore";
import { getStorage} from "firebase/storage";// Import necessary Firestore functions

const firebaseConfig = {
  apiKey: "AIzaSyBffKerwKcVQA1Mpu9GbV0F5zjx78RKa9E",
  authDomain: "form-3b2da.firebaseapp.com",
  projectId: "form-3b2da",
  storageBucket: "form-3b2da.appspot.com",
  messagingSenderId: "852698889003",
  appId: "1:852698889003:web:13dfe59c31238168ed636e"
};

// Initialize Firebase
// Initialize Firebase
const app = initializeApp(firebaseConfig);
const db = getFirestore(app); // Initialize the Realtime Database
const storage = getStorage(app);


export {app, db, storage}