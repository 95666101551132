import { Disclosure} from '@headlessui/react'
import { Bars3Icon, XMarkIcon } from '@heroicons/react/24/outline'
import { Link } from 'react-router-dom'

const navigation = [
  { name: 'HOME', href: 'https://icms2024.in/', current: false },
  { name: 'ABOUT', href: 'https://icms2024.in/case.html'},
  { name: 'THEME', href: 'https://icms2024.in/Service.html', current: false },
  { name: 'SCHEDULE', href: 'https://icms2024.in/sch.html', current: false },
  { name: 'SPONSOR', href: 'https://icms2024.in/sch.html', current: false },
  { name: 'CONTACT', href: 'https://icms2024.in/contact.html', current: false },
]



function classNames(...classes) {
  return classes.filter(Boolean).join(' ')
}

export default function Navbar() {

  

  return (

    <Disclosure as="nav" className="bg-gray-900  bg-opacity-30 rounded-full sm:rounded-1xl mt-2">
      {({ open }) => (
        <>
          <div className="mx-auto max-w-7xl px-2 sm:px-6 lg:px-8">
            <div className="relative flex h-16 items-center justify-between">
              <div className="absolute inset-y-0 left-0 flex items-center sm:hidden">
                {/* Mobile menu button*/}
                <Disclosure.Button className="relative inline-flex items-center justify-center rounded-md p-2 text-gray-400 hover:bg-gray-700 hover:text-white focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white">
                  <span className="absolute -inset-0.5" />
                  <span className="sr-only">Open main menu</span>
                  {open ? (
                    <XMarkIcon className="block h-6 w-6" aria-hidden="true" />
                  ) : (
                    <Bars3Icon className="block h-6 w-6" aria-hidden="true" />
                  )}
                </Disclosure.Button>
              </div>
              
              <div className="flex flex-1 items-center justify-center sm:items-stretch sm:justify-start">
                <div className="flex flex-shrink-0 items-center">
                <img
                  src="https://icms2024.in/images/top-logo.png"
                  alt="itsulu"
                  className="w-[50px] "
                />
                  
                </div>
                <div className="hidden sm:ml-6 sm:block">

                    
                
                    

                  <div className="flex space-x-4">
                    
               
                  {navigation.map((item) => (
                      <Link
                        key={item.name}
                        to={item.href}
                        className={classNames(
                          item.current ? 'bg-gray-100 text-m font-bolder text-gray-100' : 'text-gray-100 font-bold font-m hover:bg-gray-700 hover:text-white',
                          'rounded-md px-3 py-2 text-md font-bold'
                        )}
                        aria-current={item.current ? 'page' : undefined}
                      >
                        {item.name}
                      </Link>
                    ))}
                  </div>
                </div>
              </div>
              <div className="absolute inset-y-0 right-0 flex items-center pr-2 sm:static sm:inset-auto sm:ml-6 sm:pr-0">
                

                {/* Profile dropdown */}
                <div className="hidden lg:flex lg:flex-1 lg:justify-end">
             
          </div>
              </div>
            </div>
          </div>

          <Disclosure.Panel className="sm:hidden bg-gray-700">
            <div className="space-y-1 px-2 pb-3 pt-2">
       
              
              {navigation.map((item) => (
                <Disclosure.Button
                  key={item.name}
                  as="a"
                  href={item.href}
                  className={classNames(
                    item.current ? 'bg-gray-100 text-gray-900' : 'text-gray-100 hover:bg-gray-700 hover:text-Gray-900',
                    'block rounded-md px-3 py-2 text-md font-bold '
                  )}
                  aria-current={item.current ? 'page' : undefined}
                >
                  {item.name}
                </Disclosure.Button>
              ))}


           
          
            </div>
          </Disclosure.Panel>



        </>
      )}
    </Disclosure>
  )
}
