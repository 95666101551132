import React from 'react';

const Footer = () => {
  return (
    <>
      <footer className="bg-gray-900 relative w-full ">
        <img
          src="https://icms2024.in/images/bground.jpg"
          alt="not found"
          className="absolute object-cover w-full h-full opacity-5 object-top"
        />

        <div className="relative container mx-auto py-24 lg:px-[5rem]">
          <div className="footer-content">
            <div className="grid grid-cols-1 md:grid-cols-3 gap-8">
              <div className="flex flex-col items-center lg:items-start">
                <img
                  src="https://icms2024.in/images/d9.png"
                  alt="itsulu"
                  className="w-36 mb-8"
                />
                <h2 className="text-orange-600 font-extrabold text-2xl">
                निष्ठा धृतिः सत्यम्
                </h2>
              </div>
              <div className="flex flex-col items-center  text-white">
                <h2 className="text-white text-center font-bold text-3xl">
                  Join The <span className="text-orange-600">ICMS</span>
                  <br />
                  Experience
                </h2>
                <p className="text-gray-400 mt-5">
                  Join us at the Colloquium on Micropalaeontology & Stratigraphy
                  for insightful discussions and networking opportunities.
                </p>
              </div>
              <div className="flex flex-col items-center lg:items-end">
                <input
                  type="email"
                  id="email"
                  name="email"
                  placeholder="Your email address"
                  className="h-16 w-4/5 px-6 rounded-full mb-5"
                />
                <div className="flex justify-center w-4/5">
                  <button
                    type="button"
                    className="bg-red-500 text-white font-bold py-4 w-full rounded-full hover:bg-red-600"
                  >
                    Query Now
                  </button>
                </div>
              </div>
            </div>
          </div>

          <div className="my-10 h-px bg-gray-600"></div>

          <div className="flex justify-between mx-[2rem] text-white">
            <ul className="flex space-x-8">
              <li>
                <a href="https://icms2024.in/" className="font-bold hover:text-orange-600 hover:">
                  HOME
                </a>
              </li>
              <li>
                <a href="https://icms2024.in/case.html" className="font-bold hover:text-orange-600">
                  ABOUT
                </a>
              </li>
              <li>
                <a href="https://icms2024.in/contact.html" className="font-bold hover:text-orange-600">
                  CONTACT
                </a>
              </li>
            </ul>
          </div>
        </div>
      </footer>
      <div className="bg-gray-900 py-4 lg:px-[5rem]">
        <div className="container mx-auto flex flex-col  font-bold text-white">
          <p className=''>Copyright © 2024 University of Delhi.</p>
          <p className='right-0'>All Rights Reserved.</p>
        </div>
      </div>
    </>
  );
};

export default Footer;
