import './App.css';
// import Footer from './component/Footer';
import Form from './component/Form';
import {
  BrowserRouter as Router,
  Route,
  Routes, 
} from "react-router-dom";
 

function App() {
  return (
    <div className="App">
      <Router>
      <Routes>
        <Route element={<Form/>} path=''></Route>
      </Routes>
      </Router>
      {/* <Footer/> */}
    </div>
  );
}

export default App;
